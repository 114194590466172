import React, { useState,useRef } from 'react';
import Sketch from 'react-p5';
import './App.css'; // Import your CSS file for styling
import P5Sketch from './randomColours';
import P5Sketch4 from './banner';
import P5Sketch3 from './curve';
//import P5Sketch5 from './tsp';
import Modal from 'react-modal';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Set the modal element
Modal.setAppElement('#root'); // Change '#root' to the id of your main element

const App = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSketch, setSelectedSketch] = useState(null);

    // Create a ref for images in the slider
    const imageRefs = useRef([]);

  const openModal = (sketch) => {
    setSelectedSketch(sketch);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSketch(null);
  };

   // Slider settings
   const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // This will hide the arrows
  };

   // Function to remove focus on click
   const handleImageClick = (index) => {
    if (imageRefs.current[index]) {
      imageRefs.current[index].blur();
    }
  };

  return (
    <div className="App">
      {/* Hero Section */}
      <header className="hero">
        <div className='intro-line'>
          <img className= "logo" src = "images/jtit.jpg" alt = "logo" /> 
          <h1>Jodie Thomson</h1>
        </div>
        <p>Recent Graduate Bachelor of Information Technology, Major in Software Development</p>
        <h3>Project Samples Shown Below</h3>
      </header>

      {/* Projects Section */}
      <section className="projects">

        <div className="project-cards">
          {/* Other project cards */}
          <div className="project-card">
            <h3>Ingredients to Meals</h3>
            <img src="./images/mash.png" alt="Mash site screenshot" />
            <p>React API Mash Project.</p>
            <p>React, Javascript, APIs, CSS</p>
            <a className='temp-hide' href="https://mash.jtwebdev.com.au/" target="_blank" rel="noopener noreferrer">View Project </a>
            <p className='link-alert' id="temp-hide-text">opens in new tab</p>
          </div>
          <div className="project-card">
            <h3>Serendipity</h3>
            <img src="./images/serendipity.png" alt="Serendipity site screenshot" />
            <p>Laravel Social Media Project.</p>
            <p>php, Laravel, Database, CSS</p>
            <a className='temp-hide' href="https://serendipity.jtwebdev.com.au/" target="_blank" rel="noopener noreferrer">View Project</a>
            <p className='link-alert' id="temp-hide-text">opens in new tab</p>
          </div>
          <div className="project-card">
            <h3>Unsplash Gallery</h3>
            <img src="./images/gallery.png" alt="gallery site screenshot" />
            <p>React Unsplash Gallery Project.</p>
            <p>React, Typescript, Redux, CSS</p>
            <a href="https://www.gallery.jtwebdev.com.au/" target="_blank" rel="noopener noreferrer">View Project</a>
            <p className='link-alert'>opens in new tab</p>
          </div>

          <div className="project-card">
            <h3>Travelling Salesman Problem</h3>
            <img src="./images/tsp.png" alt="tsp screenshot" />
            <p>Travelling Salesman Problem and Solution Visual Display</p>
            <p>p5, Javascript</p>
            <a className='temp-hide-tsp' href="https://www.tsp.jtwebdev.com.au/" target="_blank" rel="noopener noreferrer">View Project</a>
            <p className='link-alert' id="temp-hide-tsp-text">opens in new tab</p>
          </div>

          {/* p5 Project Cards */}
          <div className="project-card">
            <h3>p5 Banner</h3>
            <img src="./images/banner.png" alt="banner screenshot" />
            <p>Animated Banner</p>
            <p>p5, Javascript</p>
            <button id='temp-hide-p5' className='button-link' onClick={() => openModal(<P5Sketch4 className="modal-p5"/>)}>View Project</button>
            <p id='temp-hide-p5-text' className='link-alert'>opens in modal</p>
          </div>
          <div className="project-card">
            <h3>Curve with fade</h3>
            <img src="./images/curve.png" alt="curve screenshot" />
            <p>Continuous Curve Created with Fade</p>
            <p>p5, Javascript</p>
            <button id='temp-hide-p5' className='button-link' onClick={() => openModal(<P5Sketch3 className="modal-p5"/>)}>View Project</button>
            <p id='temp-hide-p5-text' className='link-alert'>opens in modal</p>
          </div>
          <div className="project-card">
            <h3>Random Curves</h3>
            <img src="./images/random.png" alt="random screenshot" />
            <p>Random Curved Lines Generated with Curve Vertex</p>
            <p>p5, Javascript</p>
            <button id='temp-hide-p5' className='button-link' onClick={() => openModal(<div className='modal-p5' ><P5Sketch /></div>)}>View Project</button>
            <p id='temp-hide-p5-text' className='link-alert'>opens in modal</p>
          </div>
          <div className="project-card">
            <h3>Tetris</h3>
            <Slider {...sliderSettings} className='slider'>
              {[...Array(3)].map((_, i) => (
                <div key={i}>
                  <img
                    src={`./images/tetris${i + 1}.png`}
                    alt={`Tetris screenshot ${i + 1}`}
                    ref={(el) => (imageRefs.current[i] = el)}
                    onClick={() => handleImageClick(i)}
                  />
                </div>
              ))}
            </Slider>
            <p>Tetris Group Project </p>
            <p>Java, AWS, Swing</p>
          </div>
          <div className="project-card">
            <h3>Air BNB Data Display</h3>
            <Slider {...sliderSettings} className='slider'>
              <div>
                <img src="./images/ajj1.png" alt="Tetris screenshot 1" />
              </div>
              <div>
                <img src="./images/ajj2.png" alt="Tetris screenshot 2" />
              </div>
              <div>
                <img src="./images/ajj3.png" alt="Tetris screenshot 3" />
              </div>
            </Slider>
            <p>Air BNB Data Display Project</p>
            <p>Python, Tkinter, Database, Figma</p>
          </div>
          <div className="project-card">
            <h3>Online Store Mobile Application</h3>
            <Slider {...sliderSettings} className='slider'>
              <div>
                <img src="./images/fake1.png" alt="products screenshot 1" />
              </div>
              <div>
                <img src="./images/fake2.png" alt="products screenshot 2" />
              </div>
              <div>
                <img src="./images/fake3.png" alt="products screenshot 3" />
              </div>
              <div>
                <img src="./images/fake4.png" alt="products screenshot 4" />
              </div>
            </Slider>

            <p>Online Fake Store</p>
            <p>React Native, Redux</p>
          </div>
          <div className="project-card">
            <h3>WIL Project</h3>
            <Slider {...sliderSettings} className='slider'>
              <div>
                <img src="./images/wil1.png" alt="Tetris screenshot 1" />
              </div>
              <div>
                <img src="./images/wil2.png" alt="Tetris screenshot 2" />
              </div>
              <div>
                <img src="./images/wil3.png" alt="Tetris screenshot 3" />
              </div>
              <div>
                <img src="./images/wil4.png" alt="Tetris screenshot 4" />
              </div>
            </Slider>
            <p>WIL Placement Project</p>
            <p>Laravel, php, databases</p>
          </div>
          <div className="project-card">
            <h3>Live Chat</h3>
            <Slider {...sliderSettings}className='slider'>
              <div>
                <img src="./images/chat1.png" alt="Tetris screenshot 1" />
              </div>
              <div>
                <img src="./images/chat2.png" alt="Tetris screenshot 2" />
              </div>
              <div>
                <img src="./images/chat3.png" alt="Tetris screenshot 3" />
              </div>
              <div>
                <img src="./images/chat4.png" alt="Tetris screenshot 3" />
              </div>
            </Slider>
            <p>Live Chat Project: MEAN</p>
            <p>Angular, Mongo, Express, Node</p>
          </div>

          {/* Repeat the above card for each project */}
        </div>
      </section>

      {/* Modal for p5 Projects */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Sketch"
        className="modal" 
      >

        <button className="close-button" onClick={closeModal}>X</button>
        {selectedSketch}
      </Modal>
    </div>
  );
};

export default App;

