import React from 'react';
import Sketch from 'react-p5';
let t = 0;
let curve = [];
let lineSeg = 1800;
function P5Sketch3() {
    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(450, 450).parent(canvasParentRef);
    }

    const draw = (p5) => {
       
        p5.noStroke();
        //black background Square
        p5.fill(0);
        p5.rect(10, 10, 430, 430, 5 );
        //white square
        p5.fill(255);
        p5.rect(30, 30, 390, 390, 20);
        //grey square
        p5.fill(200);
        p5.rect(35, 35, 380, 380, 20);
        //blue sqaure
        p5.fill(30, 144, 255);
        p5.rect(55, 55, 340, 340, 10);
        //grid
        let x = 75;
        let y = 75;
        for (let i=0; i <=10; i++){
            p5.strokeWeight(1);
            p5.stroke(0);
            p5.line(x, y, x, 375);
            x+=30;
        }

        for (let i=0; i <=10; i++){
            p5.strokeWeight(1);
            p5.stroke(0);
            p5.line(375, y, 75, y);
            y+=30;
        }
        
        // fill the array
        for (let i = 0; i<1; i+=0.055){// the lower this increment, increases the curve speed
            let x1 = 225 + 150*p5.sin(1*t + p5.PI/2);
            let y1 = 225 + 150*p5.sin(3*t);
            curve.push({
            x:x1,
            y:y1
            })
            if(curve.length>lineSeg){
            curve.splice(0,1)
            }
            t+=0.003; // lower brings points closer         
        }
        //draw the curve with fade
        for(let i = 0; i <curve.length; i++){
            //mapping the length of the array to the reduction in alpha
            let a = p5.map(i, 0,curve.length,0,255);
            p5.stroke(255,a);
            p5.strokeWeight(3);
            p5.point(curve[i].x,curve[i].y);
        }
    }

    return (
        <Sketch setup={setup} draw={draw} className="modal-p5"/>
    )
}

export default P5Sketch3;