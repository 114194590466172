import React from 'react';
import Sketch from 'react-p5';

function P5Sketch() {
    const setup = (p5, canvasParentRef) => {
        // Set canvas to a specific size (you can adjust the sizes)
        p5.createCanvas(window.innerWidth * 0.7, window.innerHeight * 0.8).parent(canvasParentRef);
    }

    const draw = (p5) => {
        p5.frameRate(0.35);
        p5.background(0);
        
        // Wavy lines with random color, position, and size
        p5.noFill();
        p5.count = 0;
        while (p5.count < 15) { // Change number of wavy lines here
            p5.beginShape();
            p5.strokeWeight(p5.random(2, 8));
            p5.stroke(p5.random(0, 255), p5.random(0, 255), p5.random(0, 255));
            p5.curveVertex(p5.random(60, p5.width - 60), p5.random(50, p5.height - 50));
            p5.curveVertex(p5.random(60, p5.width - 60), p5.random(50, p5.height - 50));
            p5.curveVertex(p5.random(60, p5.width - 60), p5.random(50, p5.height - 50));
            p5.curveVertex(p5.random(60, p5.width - 60), p5.random(50, p5.height - 50));
            p5.curveVertex(p5.random(60, p5.width - 60), p5.random(50, p5.height - 50));
            p5.endShape();
            p5.count++;
        }
        
        // Draw black frame
        p5.noFill();
        p5.strokeWeight(47);
        p5.stroke(0);
        p5.rect(0, 0, p5.width, p5.height); // Use dynamic width and height
        p5.stroke(p5.random(0, 255), p5.random(0, 255), p5.random(0, 255));
        p5.strokeWeight(p5.random(3, 9));
        p5.rect(25, 25, p5.width - 50, p5.height - 50); // Use dynamic width and height
    }

    // Optional: Adjust canvas size when window is resized
    const windowResized = (p5) => {
        p5.resizeCanvas(window.innerWidth * 0.7, window.innerHeight * 0.8);
    }

    return (
        <Sketch setup={setup} draw={draw} windowResized={windowResized} />
    );
}

export default P5Sketch;
