import React from 'react';
import Sketch from 'react-p5';

let Rain = [];
let tx = 233;//text position
let ty = -99;
let tsp = 0.7; //change text speed
let angle = 0;
let a = 0;//alpha rainbow
let a2 = 0;//alpha cloud cover
let bfly = [];


function P5Sketch4() {
    const setup = (p5, canvasParentRef) => {
         // Reset everything when the setup runs
         Rain = []; // Clear rain array on each setup
         bfly = []; // Clear butterfly array on each setup
         tx = 233;
         ty = -99;
         tsp = 0.7;
         angle = 0;
         a = 0; // Alpha for rainbow
         a2 = 0; // Alpha for cloud cover
 
         // Reset frameCount directly
         if (p5) {
             p5.frameCount = 0;
         }
 
        p5.createCanvas(800, 500).parent(canvasParentRef);
        for (let i = 0; i < 250; i++){//change rain amount here
            Rain.push({
                x:p5.random(1,799),
                y:p5.random(-800,-100),// starts way back to avoid clumping and gaps
                sp:p5.random(0.8,2.5)//change speed
            })
        
            }
          //butterfly preset positions, sizes, speeds and boundaries. L -> R
          //probably could have made this a function - fillBfly
            bfly.push({
                x:75,
                y:320,
                size:30,
                spx:-0.05,
                spy:-0.06,
                endx: 115,
                begx: 45,
                endy:350,
                begy:300
            })
            bfly.push({
                x:170,
                y:390,
                size:25,
                spx:0.07,
                spy:0.05,
                endx: 195,
                begx: 150,
                endy:415,
                begy:370
            })
            bfly.push({
                x:192,
                y:235,
                size:20,
                spx:0.07,
                spy:0.03,
                endx: 225,
                begx: 150,
                endy:255,
                begy:225
            })
            bfly.push({
                x:335,
                y:260,
                size:40,
                spx:-0.08,
                spy:0.03,
                endx: 370,
                begx: 210,
                endy:300,
                begy:250
            })
            bfly.push({
                x:460,
                y:300,
                size:25,
                spx:-0.05,
                spy:0.02,
                endx: 485,
                begx: 435,
                endy:325,
                begy:270
            })
            bfly.push({
                x:525,
                y:380,
                size:30,
                spx:-0.06,
                spy:0.05,
                endx: 550,
                begx: 500,
                endy:425,
                begy:360
            })
            bfly.push({
                x:690,
                y:430,
                size:33,
                spx:0.03,
                spy:-0.04,
                endx: 750,
                begx: 500,
                endy:680,
                begy:430
            })
    }

    const draw = (p5) => {
        p5.background(0,150,200);//bottom blue background
        
        cloud(200,65,40);
        cloud(571, 225, 30);
        cloud(685, 52, 26);
        if(p5.frameCount > 0 ){//clouds fade out with rain coming in
            p5.fill(0,150,200 ,200 + a2)//alpha backround cover to fade clouds
            p5.noStroke();
            p5.rect(0,0,800,500);
            a2+=0.1;
        }
        if (p5.frameCount > 200 ){
            writing(); 
        }
        
        grass(); 
        
        windmill(69,157,85);

        //flower rows from back to front - could make function addFlowers
        for(let x=20;x<150;x+=35){
            flower(x,210,7);
        }
        for(let x=10;x<230;x+=35){
            flower(x,235,10);
        }
        for(let x=20;x<560;x+=38){
            flower(x,275,17);
        }
        for(let x=10;x<780;x+=45){
            flower(x,330,30);
        }
        for(let x=25;x<780;x+=60){
            flower(x,400,50);
        }
        flower(40,355,100);
        flower(147,320,143);
        flower(300,295,180);

        //adding the butterflys filled in set up - could make function - addButterfly
        for(let i = 0; i <bfly.length; i++){
            butterfly(bfly[i].x, bfly[i].y, bfly[i].size);
            bfly[i].x += bfly[i].spx;
            bfly[i].y += bfly[i].spy;
            if (bfly[i].x > bfly[i].endx || bfly[i].x <bfly[i].begx 
            || bfly[i].y > bfly[i].endy || bfly[i].y < bfly[i.begy] ){
            bfly[i].spx = - bfly[i].spx;
            bfly[i].spy = - bfly[i].spy;
            }
        }

        if (p5.frameCount >140){
            rainbow(655,140,170,150,0+a,200,25);
            if(a>=0 && a<130){
            a+=0.5;
            }
        }

        if(p5.frameCount>80){
            rain();
        }
    

        function rain(){
            for(let i = 0; i <Rain.length; i++){
                p5.strokeWeight(2)
                p5.stroke(207,238,250,220)
                p5.line (Rain[i].x,Rain[i].y, Rain[i].x, Rain[i].y+3)
                Rain[i].y += Rain[i].sp;
                if(Rain[i].y >=600){
                Rain[i].y=p5.random(-100,-50);
                }
            }
        }

        function windmill(x, y, size){
            p5.angleMode(p5.DEGREES)
            p5.noStroke();
            p5.fill(0,90,128, )
            p5.triangle(x,y,x-size/6.5, y + size/1.5 ,x+size/6.5,y + size/1.5 )
            let start = 25
            let finish = 65
            p5.arc(x,y,size,size,start + angle, finish + angle)
            p5.arc(x,y,size,size,start  +90  + angle, finish  +90  + angle)
            p5.arc(x,y,size,size,start + 180 + angle, finish + 180 + angle)
            p5.arc(x,y,size,size,start + 270 + angle, finish + 270 + angle)
            angle+= 0.45//change windmill speed here
            p5.ellipse(x,y,size/4.5,size/4.5)
            p5.fill(200, 150)
            p5.ellipse(x,y,size/8,size/8)
        }

        function butterfly(x,y,size){
            p5.angleMode(p5.DEGREES)
            //wings
            p5.noStroke();
            p5.push();
            p5.translate(x,y);
            p5.fill(65,105,225);
            p5.rotate(45);
            p5.ellipse(0,0,size/2.5,size*1.2);
            p5.fill(255,255,0);
            p5.ellipse(size/100, -size/2.5, size/10,size/10);
            p5.fill(0,0,255);
            p5.ellipse(size/100, -size/2.5, size/20,size/20);
            p5.fill(255,255,0);
            p5.ellipse(-size/100, +size/2.5, size/10,size/10);
            p5.fill(0,0,255);
            p5.ellipse(-size/100, +size/2.5, size/20,size/20);
            p5.fill(65,105,225);
            p5.rotate(90);
            p5.ellipse(0,0,size/2.5,size*1.2);
            p5.fill(255,255,0);
            p5.ellipse(-size/100, -size/2.5, size/10,size/10);
            p5.fill(0,0,255);
            p5.ellipse(-size/100, -size/2.5, size/20,size/20);
            p5.fill(255,255,0);
            p5.ellipse(+size/100, +size/2.5, size/10,size/10);
            p5.fill(0,0,255);
            p5.ellipse(+size/100, +size/2.5, size/20,size/20);
            p5.pop();
            //main body
            p5.fill(0,0,255);
            p5.ellipse(x,y,size/4.5,size);
            p5.stroke(65,105,225);
            p5.strokeWeight(size/50);
            p5.line(x, y-size/2, x, y+size/2);
            p5.strokeWeight(size/30);
            p5.stroke(255,255,0,200);
            p5.point(x - size/20, y-size/4);
            p5.point(x - size/20, y-size/12);
            p5.point(x - size/20, y+size/12);
            p5.point(x - size/20, y+size/4);
            p5.point(x + size/20, y-size/4);
            p5.point(x + size/20, y-size/12);
            p5.point(x + size/20, y+size/4);
            p5.point(x + size/20, y+size/12);
            //antenae
            p5.stroke(255,255,0);
            p5.strokeWeight(size/50);
            p5.line(x, y-size/2, x-size/10, y-size/1.75);
            p5.line(x, y-size/2, x+size/10, y-size/1.75);
            p5.strokeWeight(size/25);
            p5.stroke(65,105,225);
            p5.point(x-size/10, y-size/1.75);
            p5.point(x+size/10, y-size/1.75);
        }

        function flower (x,y,size){
            p5.angleMode(p5.DEGREES);
            //stem
            p5.stroke(0,100,0);
            p5.strokeWeight(size/13);
            p5.line(x,y, x, y+size);
            //petals
            p5.noStroke();
            p5.fill(255,255,0);
            p5.ellipse(x, y, size/4,size/4);
            p5.ellipse(x,y,size/3.5,size);
            p5.push();
            p5.translate(x,y);
            p5.rotate(45);
            p5.ellipse(0,0,size/3.5,size);
            p5.rotate(90);
            p5.ellipse(0,0,size/3.5,size);
            p5.rotate(135);
            p5.ellipse(0,0,size/3.5,size);
            p5.pop();
            p5.fill(255,0,0);
            p5.ellipse(x, y, size/3.7,size/3.7);
            p5.fill(255,255,0);
            p5.ellipse(x, y, size/13,size/13);
        }

        function rainbow(x,y,w,h,a, angleStart, angleFinish){
            p5.angleMode(p5.DEGREES)
            p5.stroke(255,0,0,a);
            p5.strokeWeight(8)
            p5.noFill();
            p5.arc(x,y,w,h,angleStart,angleFinish);
            p5.stroke(255,255,0,a);
            p5.strokeWeight(8)
            p5.arc(x,y,w*0.8,h*0.8,angleStart,angleFinish);
            p5.stroke(0,255,0, a);
            p5.strokeWeight(8)
            p5.arc(x,y,w*0.6,h*0.6,angleStart,angleFinish);
            p5.stroke(0,0,255,a);
            p5.strokeWeight(8)
            p5.arc(x,y,w*0.4,h*0.4,angleStart,angleFinish);
            p5.stroke(75,0,130,a);
            p5.strokeWeight(8)
            p5.arc(x,y,w*0.2,h*0.2,angleStart,angleFinish);
        }

        function cloud(x,y,size){
            p5.noStroke();
            p5.fill(255);
            p5.ellipse(x,y,size,size)
            p5.ellipse(x + size/2, y + size *0.16, size * 0.75,size * 0.75)
            p5.ellipse(x + size/2, y - size * 0.16, size* 0.58, size *0.58)
            p5.ellipse(x,y + size *1/3, size * 0.75 ,size* 0.75)
            p5.ellipse(x - size/2, y + size*1/3, 2/3 * size, 2/3 * size)
            p5.ellipse(x - size/2 ,y, size *0.58, size*0.58)
        }

        function grass(){
            p5.push()
            p5.noStroke();
            p5.fill (90,238,50);
            p5.rect(0, 2/3*p5.height, p5.width, 1/3 * p5.height);
            p5.beginShape();
            p5.curveVertex(0, 0.9* p5.height);
            p5.curveVertex(0, 0.9* p5.height);
            p5.curveVertex(0, p5.height - 0.9 *p5.height);
            p5.curveVertex(0, 2/3* p5.height);
            p5.curveVertex(0, 2/5* p5.height);
            p5.curveVertex(800, 2/3* p5.height);
            p5.curveVertex(800, 2/3* p5.height);
            p5.endShape(p5.CLOSE);
            p5.pop()
        }

        function writing(){
            p5.noStroke();
            if (ty>-100 && ty<400){
                p5.fill(255,150);
                p5.textSize(28);
                p5.textStyle(p5.BOLD);
                p5.text("1701ICT", tx +76,ty);
                p5.text("Creative Coding in p5", tx, ty+35);
            }
        ty = ty + tsp;
        }
    }

    return (
        <Sketch setup={setup} draw={draw} />
    )
}

export default P5Sketch4;